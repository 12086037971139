import React from "react";
import PartsButton from "./PartsButton";
import { useModel } from "../contexts/ModelContext";
import styles from "../scss/PartsButtonGroup.module.css";

export default function PartsButtonGroup() {
  const {
    state: { model },
  } = useModel();
  return (
    <div className={styles.btnGroup}>
      {model?.parts?.map((part, index) => {
        return (
          <PartsButton
            key={index}
            data_key={part.name}
            display_text={part.display_text}
            position={index}
          ></PartsButton>
        );
      })}
    </div>
  );
}
