import React from "react";
import Button from "react-bootstrap/Button";
import styles from "../scss/PartsButton.module.css";
import { useModel } from "../contexts/ModelContext";
import { IndicateLine } from "../utils/indicate_line";

export default function PartsButton(props) {
  const { dispatch } = useModel();

  const handleClick = async (e) => {

    IndicateLine.resetBorderButtons();

    e.target.className += ' partsButtonActive';

    dispatch({ type: "selectPart", payload: { partPosition: props.position } });
  }

  return (
    <Button
      id={props.data_key}
      variant="primary"
      className={styles.partsButton + ' partsButtonAction'}
      block
      data-option={props.data_key}
      onClick={(e) => handleClick(e)}
    >
      {props.display_text}
    </Button>
  );
}
