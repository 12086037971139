import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "../scss/TextureDialog.module.css";
import { useModel } from "../contexts/ModelContext";
import TextureColorButton from "../components/TextureColorButton";
import TextureImageButton from "../components/TextureImageButton";
import CustomCropper from "./CustomCropper";
import TextureText from "./TextureText";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export default function TextureDialog(props) {
  const {
    state: { part, shouldTextureDialogShown },
  } = useModel();
  const { dispatch } = useModel();

  const hideDialog = () => {
    dispatch({ type: "hideDialog" });
  };

  const chooseDialogType = () => {
    switch (part?.textures_mode) {
      case "color":
      case "image":
        return (
          <Tabs>
            <TabList style={{ textAlign: "left", marginBottom: "0px" }}>
              <Tab style={{ backgroundColor: "#d6d3d3" }}><span>{part?.tab_name}</span></Tab>
              <button
                id="close-dialog"
                className={"react-tabs__tab close-dialog"}
                style={{ float: "right", backgroundColor: "#d6d3d3" }}
                onClick={() => hideDialog()}
              >
                <FontAwesomeIcon icon={faTimes}/>
              </button>
            </TabList>
            <TabPanel>
              <div className={styles.dialogBodyContent}>
                <div className={styles.textures}>
                  {part?.textures?.map((texture, index) => {
                    return chooseButtonType(texture, index);
                  })}
                </div>
              </div>
            </TabPanel>
          </Tabs>
        );
      case "image_upload":
        return (
          <Tabs>
            <TabList style={{ textAlign: "left", marginBottom: "0px" }}>
              <Tab style={{ backgroundColor: "#d6d3d3" }}><span>{part?.tab_name}</span></Tab>
              <button
                className={"react-tabs__tab close-dialog"}
                style={{ float: "right", backgroundColor: "#d6d3d3" }}
                onClick={() => hideDialog()}
              >
                <FontAwesomeIcon icon={faTimes}/>
              </button>
            </TabList>
            <TabPanel>
              <div className={styles.dialogBodyContent}>
                <div className={styles.textures}>
                  <div>
                    <CustomCropper></CustomCropper>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        );
      case "image_text":
        return <TextureText></TextureText>;
      default:
        return "";
    }
  };

  if (shouldTextureDialogShown) {
    return <div className={styles.dialog}>{chooseDialogType()}</div>;
  } else {
    return <div></div>;
  }
}

function chooseButtonType(texture, index) {
  switch (texture.type) {
    case "color":
      return (
        <TextureColorButton
          key={texture.key}
          customKey={texture.key}
          text={texture.display_text}
          value={texture.value}
          position={index}
        ></TextureColorButton>
      );
    case "image":
      return (
        <TextureImageButton
          key={texture.key}
          customKey={texture.key}
          text={texture.display_text}
          value={texture.thumbnail}
          position={index}
          isHidden={texture.is_hidden}
        ></TextureImageButton>
      );
    // case "image_upload":
    //   return (
    //     <div key={texture.key} style={{ position: "relative", width: "15rem", height: "180px" }}>
    //       <CustomCropper key={texture.key} value={texture.value}></CustomCropper>
    //     </div>
    //   );
    // case "image_text":
    //   return (
    //     <div>
    //       <TextureText isHidden={texture.is_hidden}></TextureText>
    //     </div>
    //   );

    default:
      break;
  }
  return <div>abc</div>;
}
