import * as THREE from "three";

let lines = [];
let gridHelper;
var hideLinesTimeout;
var isHiddenLines;
let orginalLineMaterial;
let lineGeometry = new THREE.BufferGeometry();

export class IndicateLine {
  static async draw(camera, mouse) {
    gridHelper.quaternion.copy(camera.quaternion);

    for await (const line of lines) {
      line.raycaster.setFromCamera(
        // [line.end_point[0] * window.devicePixelRatio, line.end_point[1] * window.devicePixelRatio],
        line.end_point,
        camera
      );
      let intersects = line.raycaster.intersectObjects([gridHelper]);

      if (intersects.length > 0) {
        this.updateLine(line.line, line.startPoint, intersects[0].point);
      }
    }
  }

  static updateLine(line, startPoint, endPoint) {
    line.geometry.dispose();
    if (isHiddenLines) {
      // endPoint.x = startPoint.geometry.attributes.position.array[0];
      // endPoint.y = startPoint.geometry.attributes.position.array[1];
      // endPoint.z = startPoint.geometry.attributes.position.array[2];
      line.geometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 0, 0),
      ]);
    } else {
      line.geometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(
          startPoint.geometry.attributes.position.array[0],
          startPoint.geometry.attributes.position.array[1],
          startPoint.geometry.attributes.position.array[2]
        ),
        new THREE.Vector3(endPoint.x, endPoint.y, endPoint.z),
      ]);
    }
  }

  static addLines(scene, selectedModel) {
    const lineSettings = this.readLineSettings(selectedModel);
    var pointStartGeometry;
    var pointMaterial;
    var lineMaterial;
    var pointStart;
    var line;

    lineSettings.forEach((lineSetting) => {
      // point_start
      pointStartGeometry = new THREE.BufferGeometry();
      pointStartGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(lineSetting.start_point, 3)
      );
      pointMaterial = new THREE.PointsMaterial({
        color: lineSetting.color,
      });

      pointStart = new THREE.Points(pointStartGeometry, pointMaterial);
      pointStart.visible = false;
      scene.add(pointStart);
      // end point_start

      // line
      orginalLineMaterial = new THREE.LineBasicMaterial({
        color: "#9133D9",
        linewidth: lineSetting.line_width,
      });
      lineMaterial = new THREE.LineBasicMaterial({
        color: lineSetting.color,
        linewidth: lineSetting.line_width,
      });
      line = new THREE.Line(lineGeometry, lineMaterial);
      line.name = lineSetting.name;
      line.linewidth = lineSetting.line_width;
      scene.add(line);

      lines.push({
        line: line,
        startPoint: pointStart,
        end_point: new THREE.Vector2(this.getX(lineSetting.name), this.getY(lineSetting.name)),
        raycaster: new THREE.Raycaster(),
      });
    });

    // gridHelper = new THREE.GridHelper(100, 100);
    // gridHelper.geometry.rotateZ(-Math.PI / 2);
    // gridHelper.geometry.rotateY(-Math.PI / 2);
    // gridHelper.visible = false;

    const geometry = new THREE.BoxGeometry(
      100 * window.devicePixelRatio,
      30 * window.devicePixelRatio,
      1
    );
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    gridHelper = new THREE.Mesh(geometry, material);
    gridHelper.receiveShadow = false;
    gridHelper.castShadow = false;
    gridHelper.visible = false;

    scene.add(gridHelper);
  }

  static getX(name) {
    return (
      (this.average(
        document.getElementById(name).getBoundingClientRect().left,
        document.getElementById(name).getBoundingClientRect().right
      ) /
        window.innerWidth) *
        2 -
      1
    );
  }

  static getY(name) {
    return (
      -(
        this.average(
          document.getElementById(name).getBoundingClientRect().top,
          document.getElementById(name).getBoundingClientRect().bottom
        ) / window.innerHeight
      ) *
        2 +
      1
    );
  }

  static average(a, b) {
    return (a + b) / 2;
  }

  static readLineSettings(selectedModel) {
    //Adapter
    return selectedModel.parts.map((p) => {
      return p.indicate_line;
    });
  }

  static showLines() {
    isHiddenLines = false;
  }

  static temporaryHideLines() {
    isHiddenLines = true;
    clearTimeout(hideLinesTimeout);
    hideLinesTimeout = setTimeout(this.showLines, 200);
  }

  static setActiveLineColor(selectedPartName) {
    if (selectedPartName === "") return;
    for (const line of lines) {
      if (selectedPartName === line.line.name) {
        line.line.material = new THREE.LineBasicMaterial({
          color: "#FFFF00",
          linewidth: line.line.linewidth,
        });
      } else {
        line.line.material = orginalLineMaterial;
      }
    }

    this.resetBorderButtons();
    var partButtons = document.querySelectorAll(".partsButtonAction");
    Array.from(partButtons).forEach((element) => {
      if (element.id === selectedPartName) {
        element.classList.add("partsButtonActive");
      }
    });
  }

  static resetBorderButtons() {
    var partButtons = document.querySelectorAll(".partsButtonAction");
    Array.from(partButtons).forEach((element) => {
      element.className = element.className.replace(" partsButtonActive", "");
    });
  }
}
