import "./App.css";
import ThreeDWorld from "./components/3dWorld";
import PartsButtonGroup from "./components/PartsButtonGroup";
import Header from "./components/Header";
import { ModelProvider } from "./contexts/ModelContext";
import BottomMenu from "./components/BottomMenu";
import TextureDialog from "./components/TextureDialog";
import { SnackbarProvider } from "notistack";
import Slide from "@material-ui/core/Slide";

function App() {
  return (
    <div className="App">
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        TransitionComponent={Slide}
      >
        <ModelProvider>
          <Header></Header>

          <PartsButtonGroup></PartsButtonGroup>

          <ThreeDWorld></ThreeDWorld>
          <BottomMenu></BottomMenu>

          <TextureDialog></TextureDialog>
        </ModelProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
