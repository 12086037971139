import React from "react";
import styles from "../scss/TextureImageButton.module.css";
import { useModel } from "../contexts/ModelContext";

export default function TextureImageButton(props) {
  const {
    state: { part, selection },
    dispatch,
  } = useModel();

  const handleClick = async () => {
    const oldTexture = part.textures.find((texture) => {
      return selection[part.name]?.key === texture.key;
    });
    const oldTexturePosition = part.textures.indexOf(oldTexture);
    dispatch({
      type: "selectTexture",
      payload: { oldTexturePosition: oldTexturePosition, texturePosition: props.position },
    });
  };
  return (
    <div className={styles.btnWrapper} style={props.isHidden ? { display: "none" } : {}}>
      <div
        className={
          checkActive(props, part, selection) ? styles.outLineCircleActive : styles.outLineCircle
        }
        onClick={() => handleClick()}
      >
        <img src={props.value} height="120px" alt={props.text}></img>
        <p>{props.text}</p>
      </div>
    </div>
  );
}

function checkActive(props, part, selection) {
  if (selection[part.name].key === props?.customKey) {
    return true;
  } else {
    return false;
  }
}
