import React from "react";
import styles from "../scss/TextureColorButton.module.css";
import { useModel } from "../contexts/ModelContext";

export default function TextureColorButton(props) {
  const {
    state: { part, selection },
    dispatch,
  } = useModel();

  const handleClick = async () => {
    const oldTexture = part.textures.find((texture) => {
      return selection[part.name]?.key === texture.key;
    });
    const oldTexturePosition = part.textures.indexOf(oldTexture);

    dispatch({
      type: "selectTexture",
      payload: { oldTexturePosition: oldTexturePosition, texturePosition: props.position },
    });
  };

  return (
    <div className={styles.btnWrapper}>
      <div
        onClick={() => handleClick()}
        className={
          checkActive(props, part, selection) ? styles.outLineCircleActive : styles.outLineCircle
        }
      >
        <p className={styles.circle} style={{ backgroundColor: props.value }}></p>
        <p className={styles.circleText}>{props.text}</p>
      </div>
    </div>
  );
}

function checkActive(props, part, selection) {
  if (selection[part.name]?.key === props?.customKey) {
    return true;
  } else {
    return false;
  }
}
