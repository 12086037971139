import React from "react";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function FontDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      TransitionComponent={Transition}
    >
      {/* <DialogTitle id="simple-dialog-title">Choose font</DialogTitle> */}
      <List>
        {props.fonts?.map((font) => (
          <ListItem button onClick={() => handleListItemClick(font.value)} key={font.key}>
            <div style={{ fontFamily: font.value }}>{font.display_text}</div>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
FontDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
