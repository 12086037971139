import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import Button from "react-bootstrap/Button";
import "../scss/CustomCropperStyle.css";
import { useModel } from "../contexts/ModelContext";
import Slider from "@material-ui/core/Slider";
// import CircularProgress from "@material-ui/core/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchMinus, faSearchPlus, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";

export default function CustomCropper() {
  const ZOOM_MIN = 0.4;
  const ZOOM_MAX = 3;
  const ZOOM_STEP = 0.1;
  const {
    state: { part, selection },
    dispatch,
  } = useModel();
  const [imageSrc, setImageSrc] = useState(selection[part.name].thumbnail);
  const [imageEmptySrc] = useState(selection[part.name].thumbnail_empty);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(selection[part.name].aspect);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    const currentTexture = selection[part.name];
    if (currentTexture) {
      setCrop(currentTexture.crop);
      setZoom(currentTexture.zoom);
      setAspect(currentTexture.aspect)
      setCroppedAreaPixels(currentTexture.cropped_area_pixels);
    }
  }, [selection[part.name]]);

  useEffect(() => {
    if (imageSrc && croppedAreaPixels) {
      var img = new Image();
      // img.onload = function() {
      //   setAspect(this.width / this.height);
      // }
      img.src = imageSrc;
    }
    setIsEmpty(imageSrc === imageEmptySrc);
    if (imageSrc === imageEmptySrc) {
      showCroppedImage();
    }
  }, [imageSrc]);

  const showCroppedImage = () => {
    try {
      const oldTexture = part.textures.find((texture) => {
        return selection[part.name]?.key === texture.key;
      });
      const oldTexturePosition = part.textures.indexOf(oldTexture);
      dispatch({
        type: "selectTexture",
        payload: {
          oldTexturePosition: oldTexturePosition,
          customTexture: {
            key: uuidv4(),
            thumbnail: imageSrc,
            thumbnail_empty: imageEmptySrc,
            type: "image_upload",
            zoom: zoom,
            crop: crop,
            aspect: aspect,
            cropped_area_pixels: croppedAreaPixels,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };
  const zoomIn = () => {
    if (zoom < ZOOM_MAX) {
      setZoom(zoom + ZOOM_STEP);
    }
  };
  const zoomOut = () => {
    if (zoom > ZOOM_MIN) {
      setZoom(zoom - ZOOM_STEP);
    }
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl);
    } 
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  return (
    <div>
      <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
        <div className="crop-container">
          {!isEmpty ? (
            <Cropper
              minZoom={ZOOM_MIN}
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
              restrictPosition={false}
            />
          ) : (
            <input type="file" onChange={onFileChange} accept="image/*" />
          )}
        </div>
      </div>
      {!isEmpty ? (
        <div style={{ marginTop: "1rem" }}>
          <div className="SliderCombo">
            <FontAwesomeIcon
              icon={faSearchMinus}
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={zoomOut}
            />
            <Slider
              value={zoom}
              min={ZOOM_MIN}
              max={ZOOM_MAX}
              step={ZOOM_STEP}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              classes={{ root: "slider" }}
            />
            <FontAwesomeIcon
              icon={faSearchPlus}
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={zoomIn}
            />
          </div>
          <Button onClick={() => showCroppedImage()} style={{ width: "4rem", marginRight: "5px" }}>
            <FontAwesomeIcon icon={faCheck} />
          </Button>
          <Button
            variant="secondary"
            style={{ width: "4rem", marginRight: "5px" }}
            onClick={() => {
              setImageSrc(imageEmptySrc);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
