import React, { Component } from "react";
import * as THREE from "three";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";

import { IndicateLine } from "../utils/indicate_line.js";
import { BrowserUtils } from "../utils/BrowserUtils.js";
import { getCroppedImg } from "../utils/canvasUtils";
import html2canvas from "html2canvas";
import { ModelContext } from "../contexts/ModelContext";
import AppBackdrop from "./Backdrop.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import { withSnackbar } from "notistack";
import Stats from "three/examples/jsm/libs/stats.module.js";

let selectedModel = null;
let selectedPart;
let camera;
let scene;
let renderer;
let targetModel;
let controls;
let raycasterTargetObj = [];
let lightHolder;
let INTERSECTED;
let raycaster = new THREE.Raycaster();
let mouse = new THREE.Vector3(100, 100, 100);
let materialList = {};
let isDragging = false;
let isMousePressed = false;
let dispatch;
let composer, effectFXAA, outlinePass;
let selectedObjects = [];
let floor;
let stats;
let spotLight, spotLight3, dirLight;
class ThreeDWorld extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }
  componentDidMount() {
    this.setupDefaultSettings();
  }
  componentDidUpdate(previousProps, previousState) {
    // console.log(previousProps);
    // console.log(previousState);
    dispatch = this.context.dispatch;
    if (!this.context.state?.model) {
      return;
    }

    if (selectedModel !== this.context.state?.model) {
      // This is new model, so reload the 3D World
      selectedModel = this.context.state.model;

      this.init3DWorld();
    }

    if (selectedPart !== this.context.state.part) {
      selectedPart = this.context.state.part;
    }
    if (selectedPart) {
      this.updateTexture(targetModel, selectedPart.name);
      this.highlightSelectedPart(selectedPart.name);
    } else {
      this.highlightSelectedPart();
    }
  }
  setupDefaultSettings = () => {
    let sceneElement = document.getElementById("scene");
    if (BrowserUtils.isMobileOrTablet()) {
      sceneElement.addEventListener("touchstart", (e) => this.onTouchStart(e), false);
      sceneElement.addEventListener("touchmove", (e) => this.onTouchMove(e), false);
      sceneElement.addEventListener("touchend", (e) => this.onTouchEnd(e), false);
    } else {
      if (window.PointerEvent) {
        sceneElement.addEventListener("pointerup", (e) => this.onMouseUp(e), false);
        sceneElement.addEventListener("pointermove", (e) => this.onMouseMove(e), false);
        sceneElement.addEventListener("pointerdown", (e) => this.onMouseDown(e), false);
      } else {
        sceneElement.addEventListener("mouseup", (e) => this.onMouseUp(e));
        sceneElement.addEventListener("mousemove", (e) => this.onMouseMove(e), false);
        sceneElement.addEventListener("mousedown", (e) => this.onMouseDown(e), false);
      }
    }



    window.addEventListener("resize", () => this.resizeRendererToDisplaySize());
    const _this = this;

    document.addEventListener("keydown", function (event) {
      //control + shift +z
      if (event.ctrlKey && event.shiftKey && event.key === "Z") {
        dispatch({ type: "redo" });
      }
      //control + z
      if (event.ctrlKey && event.key === "z") {
        dispatch({ type: "undo" });
      }
    });

    document.getElementById("printscreen").onclick = () => {
      IndicateLine.temporaryHideLines();
      setTimeout(() => {
        _this.takeScreenshot();
      }, 100);
    };
  };

  init3DWorld = async () => {
    if (!selectedModel) {
      return;
    }
    const fogColor = 0xa0a0a0;
    scene = new THREE.Scene();
    // scene.background = new THREE.Color(this.context.state.scene.background_color);
    scene.background = new THREE.Color(fogColor);
    scene.fog = new THREE.Fog(fogColor, 0, 200);
    camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.set(0, 8, 40);

    // const axesHelper = new THREE.AxesHelper(100);
    // scene.add(axesHelper);

    floor = new THREE.Mesh(
      new THREE.CircleGeometry(500, 32),
      new THREE.MeshPhongMaterial({
        color: new THREE.Color(this.context.state.scene.background_color),
        depthWrite: false,
      })
    );
    floor.position.set(0, -5, 0);
    // floor.rotation.x = -Math.PI / 2;
    floor.geometry.rotateX(-Math.PI / 2);
    floor.receiveShadow = true;
    floor.geometry.radius = 20;
    scene.add(floor);

    // Setup light
    lightHolder = new THREE.Group();
    scene.add(lightHolder);

    const hemiLight = new THREE.HemisphereLight(
      0xffffff, new THREE.Color(this.context.state.scene.background_color),
      0.6
    );
    hemiLight.position.set(50, 50, 50);
    lightHolder.add(hemiLight);

    // const hemiLightHelper = new THREE.HemisphereLightHelper(hemiLight, 10);
    // scene.add(hemiLightHelper);

    if (this.context.state.scene.dir_light) {
      dirLight = new THREE.DirectionalLight(0xffffff, 0.08);
      dirLight.position.set(-25, 10, 15);
      dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
      dirLight.target.position.set(25, 10, 0);
      dirLight.castShadow = true;
      lightHolder.add(dirLight);
      lightHolder.add(dirLight.target);
    }


    //SpotLight0
    if (this.context.state.scene.spot_light_0) {
      spotLight = new THREE.SpotLight(0xffffff, 1.2, 120, Math.PI / 3, 2);
      spotLight.position.set(0, 100, 0);
      spotLight.castShadow = true;
      spotLight.shadow.mapSize.width = 1024;
      spotLight.shadow.mapSize.height = 1024;
      spotLight.shadow.radius = 20;
      scene.add(spotLight);
    }

    // const lightHelper0 = new THREE.SpotLightHelper(spotLight);
    // scene.add(lightHelper0);
    // SpotLight0

    //SpotLight3
    if (this.context.state.scene.spot_light_3) {
      spotLight3 = new THREE.SpotLight(0xffffff, 1.4, 47, 0.34, 1);
      spotLight3.position.set(0, 30, 30);
      spotLight3.castShadow = true;
      spotLight3.shadow.mapSize.width = 1024;
      spotLight3.shadow.mapSize.height = 1024;
      lightHolder.add(spotLight3);
    }


    // lightHelper = new THREE.SpotLightHelper(spotLight3);
    // scene.add(lightHelper);
    //SpotLight3

    //SpotLight4
    // const spotLight4 = new THREE.SpotLight(0xffffff, 1, 40, 0.34, 1);
    // spotLight4.position.set(25, 20, 20);
    // spotLight4.castShadow = true;
    // spotLight4.shadow.mapSize.width = 1024;
    // spotLight4.shadow.mapSize.height = 1024;
    // lightHolder.add(spotLight4);

    // lightHelper = new THREE.SpotLightHelper(spotLight4);
    // scene.add(lightHelper);
    //SpotLight4

    const light = new THREE.AmbientLight(0xffffff, 0.2); // soft white light
    scene.add(light);

    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);

    this.mount.innerHTML = "";
    this.mount.appendChild(renderer.domElement);

    if (this.context.state.scene.show_fps) {
      stats = new Stats();
      this.mount.appendChild(stats.dom);
    }


    let loader = new FBXLoader();
    loader
      .loadAsync(selectedModel.path)
      .then(async (object) => {
        targetModel = object;
        targetModel.traverse(function (child) {
          if (child instanceof THREE.Mesh) {
            child.geometry.computeBoundingSphere();
            child.receiveShadow = true;
            child.castShadow = true;
          }
        });

        // //Set model a litte bit higher because the screen is verical
        // if (BrowserUtils.isMobileOrTablet()) {
        //   targetModel.position.y = 4;
        // }
        targetModel.position.y = 4;

        targetModel.name = "TargetModel";
        scene.add(targetModel);

        camera.lookAt(targetModel.position);

        this.getRaycasterTargetObj();
      })
      .catch((error) => {
        console.log("LoadFBX fail");
        console.log(error);
      })
      .then(() => {
        // Load default texture
        if (this.context.state.selection) {
          Object.entries(this.context.state.selection).map(async ([partName, texture]) => {
            await this.initDefaultTexture(targetModel, partName, texture);
          });
        }
      })
      .then(() => {
        setTimeout(() => {
          this.setState({ loading: false });
          this.props.enqueueSnackbar("Session loaded", { variant: "success" });
        }, 200);
      });
    this.setupControls();
    this.setupLines();
    this.setupOutline();

    this.animate();
  };

  setupOutline = () => {
    composer = new EffectComposer(renderer);

    const renderPass = new RenderPass(scene, camera);
    composer.addPass(renderPass);

    outlinePass = new OutlinePass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      scene,
      camera
    );
    outlinePass.renderToScreen = true;
    outlinePass.edgeStrength = Number(10);
    outlinePass.edgeGlow = Number(0);
    outlinePass.edgeThickness = Number(1.0);
    outlinePass.pulsePeriod = Number(0);
    outlinePass.usePatternTexture = false;
    outlinePass.visibleEdgeColor.set("#FFFF00");
    outlinePass.hiddenEdgeColor.set("#FFFF00");
    composer.addPass(outlinePass);

    const textureLoader = new THREE.TextureLoader();
    textureLoader.load("texture/tri_pattern.jpg", function (texture) {
      outlinePass.patternTexture = texture;
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
    });

    effectFXAA = new ShaderPass(FXAAShader);
    effectFXAA.uniforms["resolution"].value.set(1 / window.innerWidth, 1 / window.innerHeight);
    effectFXAA.renderToScreen = true;
    composer.addPass(effectFXAA);
  };

  setupControls = () => {
    controls = new OrbitControls(camera, renderer.domElement);
    controls.maxPolarAngle = Math.PI / 2;
    controls.minPolarAngle = Math.PI / 3;
    controls.enableDamping = true;
    controls.enablePan = false;
    controls.dampingFactor = 0.3;
    controls.autoRotate = false; // Toggle this if you'd like the chair to automatically rotate
    controls.autoRotateSpeed = 0.2; // 30
    controls.maxDistance = 50;
    controls.minDistance = 30;
    controls.addEventListener("change", () => {
      // Comment for FIX: ポインターが表示されなくなったので表示して下さい
      // IndicateLine.temporaryHideLines();
    });
  };

  setupLines = () => {
    if (selectedModel) {
      IndicateLine.addLines(scene, selectedModel);
    }
  };

  initDefaultTexture = async (parent, type, texture) => {
    if (!texture) {
      //has no texture
      return;
    }

    var material;
    material = await this.getMaterial(texture);

    parent.traverse((o) => {
      if (o.isMesh && o.name === type) {
        o.material = material;
      }
    });
  };

  getRaycasterTargetObj = () => {
    //filter object with name

    var objects = scene.children.filter((o) => {
      return o.name === "TargetModel";
    });

    var cupObject = objects;
    if (objects.length > 0) {
      cupObject = objects[0].children;
      cupObject = cupObject.filter((o) => {
        return o.type === "Mesh";
      });
    }

    raycasterTargetObj = cupObject;
  };

  getMaterial = async (texture) => {
    let material;
    switch (texture.type) {
      case "color":
        material = new THREE.MeshPhongMaterial({
          color: texture.value,
          transparent: true,
        });
        break;
      case "image_upload": {
        await getCroppedImg(texture.thumbnail, texture.cropped_area_pixels).then((image) => {

          let map = new THREE.TextureLoader().load(image);
          let normalMap = new THREE.TextureLoader().load("./texture/normal_map.png");

          map.repeat.set(1, 1, 1);
          map.wrapS = THREE.RepeatWrapping;
          map.wrapT = THREE.RepeatWrapping;

          material = new THREE.MeshPhongMaterial({
            map: map,
            transparent: true,
            normalMap: normalMap,
          });
        });
        break;
      }
      case "image":
        {
          let map = new THREE.TextureLoader().load(texture.value);
          let normalMap = new THREE.TextureLoader().load("./texture/normal_map.png");

          map.repeat.set(1, 1, 1);
          map.wrapS = THREE.RepeatWrapping;
          map.wrapT = THREE.RepeatWrapping;

          material = new THREE.MeshPhongMaterial({
            map: map,
            transparent: false,
            normalMap: normalMap,
            alphaTest: 0.1,
          });
        }
        break;
      case "image_text":
        {
          let captureDiv = this.getCaptureDiv(
            texture.font_family,
            texture.font_size,
            texture.text_color,
            texture.value
          );
          let normalMap = new THREE.TextureLoader().load("./texture/normal_map.png");
          await html2canvas(captureDiv, {
            backgroundColor: "rgba(0,0,0,0)",
          }).then((canvas) => {
            let map = new THREE.CanvasTexture(canvas);
            material = new THREE.MeshPhongMaterial({
              map: map,
              transparent: true,
              // normalMap: normalMap,
              alphaTest: 0.05,
            });
          });
        }

        break;

      default:
        break;
    }
    return material;
  };

  getCaptureDiv(font_family, font_size, text_color, value) {
    let captureDiv = document.getElementById("capture");
    if (captureDiv) {
      document.body.removeChild(captureDiv);
    }

    captureDiv = document.createElement("div");
    captureDiv.id = "capture";
    captureDiv.style.width = "200px";
    captureDiv.style.height = "50px";
    captureDiv.style.margin = "auto";
    captureDiv.style.display = "flex";
    captureDiv.style.justifyContent = "center";
    captureDiv.style.alignItems = "center";
    captureDiv.style.background = "transparent";
    captureDiv.style.fontFamily = font_family;
    captureDiv.style.fontSize = font_size + "px";
    captureDiv.style.color = text_color;
    const canvasChild = document.createElement("div");
    canvasChild.innerHTML = value;
    captureDiv.append(canvasChild);
    document.body.appendChild(captureDiv);
    return captureDiv;
  }

  setMaterial = (parent, type, mtl) => {
    parent.traverse((o) => {
      if (o.isMesh && o.name != null && o.name === type) {
        o.material = mtl;
      }
    });
  };

  selectModel = (position) => {
    //Find model by name
    // selectedModel = this.context.state.models.find(function (model) {
    //   return model.name == modelName;
    // });
    this.context.dispatch({
      type: "selectModel",
      payload: { modelPosition: position },
    });
    //generatePartButtons(selectedModel.parts);

    //selectPart(selectedModel.parts[0].name);
  };

  preAnimate = () => {
    // update the picking ray with the camera and mouse position
    //raycaster.setFromCamera(mouse, camera);

    // Comment for FIX: オブジェクト選択時の境界線が、マウスオーバー時に表示されていますが、オブジェクト選択中のみ表示するようにして下さい
    // calculate objects intersecting the picking ray
    // if (!this.isDialogShowing()) {
    //   this.highlightHoveredObject();
    // }
    IndicateLine.draw(camera, mouse, scene);

    lightHolder.quaternion.copy(camera.quaternion);
    //floor.quaternion.copy(camera.quaternion);
  };

  animate = function () {
    requestAnimationFrame(() => this.animate());
    controls.update();
    this.preAnimate();

    if (this.context.state.scene.show_fps) {
      stats.update();
    }

    renderer.render(scene, camera);
    composer.render();
  };

  render() {
    return (
      <div style={{ backgroundColor: "#dee2e5" }}>
        {/* <div style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
          <LinearProgress hidden={!this.state.loading} />
        </div> */}
        <div
          id="scene"
          style={{ height: "100vh", width: "100vw" }}
          ref={(ref) => (this.mount = ref)}
        />

        <AppBackdrop open={this.state.loading}></AppBackdrop>
      </div>
    );
  }

  // --------------------------------------------------------------------
  // Events
  onTouchStart(event) {
    var x = event.touches[0].clientX;
    var y = event.touches[0].clientY;

    mouse.x = (x / window.innerWidth) * 2 - 1;
    mouse.y = -(y / window.innerHeight) * 2 + 1;
  }
  onTouchMove(event) {
    isDragging = true;
    var x = event.touches[0].clientX;
    var y = event.touches[0].clientY;

    mouse.x = (x / window.innerWidth) * 2 - 1;
    mouse.y = -(y / window.innerHeight) * 2 + 1;
  }
  onTouchEnd(event) {
    if (isDragging) {
      isDragging = false;
    } else {
      this.choosePartByClickingOnModel(event);
    }
    mouse.x = -1;
    mouse.y = -1;
  }
  onMouseDown(event) {
    isMousePressed = true;

    let element = event.target;
    if (element && element.id) {
      Array.from(raycasterTargetObj).forEach((item) => {
        if (element.id === item.name) {
          this.addSelectedObject(item);
          outlinePass.selectedObjects = selectedObjects;
          IndicateLine.setActiveLineColor(item.name);
        }
      });
    } else if (element.className && element.className.toString().includes("close-dialog")) {
      outlinePass.selectedObjects = [];
      INTERSECTED = null;
      IndicateLine.setActiveLineColor();
      IndicateLine.resetBorderButtons();
    }
  }
  onMouseMove(event) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    if (isMousePressed) {
      isDragging = true;
    }

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }
  onMouseUp(event) {
    let path = event.path || (event.composedPath && event.composedPath());
    if (path && path[0].localName !== "canvas") {
      INTERSECTED = null;
    }
    if (!isDragging) {
      this.choosePartByClickingOnModel(event);
    }

    isDragging = false;
    isMousePressed = false;
  }

  choosePartByClickingOnModel = (e) => {
    this.highlightSelectedPart();

    if (INTERSECTED) {
      var target = document.querySelectorAll("[data-option='" + INTERSECTED.name + "']");
      if (target.length) {
        target[0].click();
        IndicateLine.setActiveLineColor(selectedPart.indicate_line.name);
      }
    }
  };

  addSelectedObject(object) {
    selectedObjects = [];
    selectedObjects.push(object);
  }

  highlightSelectedPart(partName = null) {
    INTERSECTED = null;
    if (partName) {
      targetModel?.traverse((o) => {
        if (o.isMesh && o.name === partName) {
          INTERSECTED = o;
          return;
        }
      });
    } else {
      //Find INTERSECTED
      raycaster.setFromCamera(mouse, camera);
      var intersects = raycaster.intersectObjects(raycasterTargetObj, true);
      if (intersects.length > 0) {
        INTERSECTED = intersects[0].object;
      }
    }

    if (INTERSECTED) {
      this.addSelectedObject(INTERSECTED);
      outlinePass.selectedObjects = selectedObjects;
      IndicateLine.setActiveLineColor(INTERSECTED.name);
    } else {
      outlinePass.selectedObjects = [];
      INTERSECTED = null;
      IndicateLine.setActiveLineColor();
      IndicateLine.resetBorderButtons();
    }
  }

  updateTexture = (targetModel, partName) => {
    let newMaterial;
    let texture = this.context.state.selection[selectedPart.name];
    let key = texture?.key;
    if (!key || !targetModel) {
      return;
    }

    if (materialList[key]) {
      newMaterial = materialList[key];
      this.setMaterial(targetModel, partName, newMaterial);
    } else {
      this.getMaterial(texture).then((newMaterial) => {
        materialList[key] = newMaterial;
        this.setMaterial(targetModel, partName, newMaterial);
      });
    }
  };

  resizeRendererToDisplaySize() {
    // camera.aspect =
    //   window.innerWidth / window.innerHeight;
    // camera.updateProjectionMatrix();
    // renderer.setSize(
    //   window.innerWidth,
    //   window.innerHeight
    // );

    const width = window.innerWidth;
    const height = window.innerHeight;

    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    renderer.setSize(width, height);
    composer.setSize(width, height);

    effectFXAA.uniforms["resolution"].value.set(1 / width, 1 / height);
  }

  takeScreenshot() {
    // open in new window like this
    //
    var w = window.open("", "");
    w.document.title = "Screenshot";
    //w.document.body.style.backgroundColor = "red";
    var img = new Image();
    // Without 'preserveDrawingBuffer' set to true, we must render now
    renderer.render(scene, camera);
    img.src = renderer.domElement.toDataURL();
    w.document.body.appendChild(img);
  }

  isDialogShowing() {
    return document.getElementsByClassName("MuiDialog-root").length > 0;
  }
}
ThreeDWorld.contextType = ModelContext;
export default withSnackbar(ThreeDWorld);
