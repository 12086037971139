import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Col from "react-bootstrap/Col";
import { useModel } from "../contexts/ModelContext";
import { v4 as uuidv4 } from "uuid";
import FontDialog from "./FontDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styles from "../scss/TextureDialog.module.css";
import colorStyles from "../scss/TextureColorButton.module.css";

export default function TextureText(props) {
  const {
    state: { part, selection },
    dispatch,
  } = useModel();
  const [openFontDialog, setOpenFontDialog] = React.useState(false);
  const [fontFamily, setFontFamily] = useState("Robo Noto San JP");
  const [fontSize, setFontSize] = useState(20);
  const [text, setText] = useState("TEXT DEFAULT");
  const [textColor, setTextColor] = useState("#000000");
  useEffect(() => {
    const currentTexture = selection[part.name];
    if (currentTexture) {
      setFontFamily(currentTexture.font_family);
      setFontSize(currentTexture.font_size);
      setText(currentTexture.value);
      setTextColor(currentTexture.text_color);
    }
  }, [selection[part.name]]);

  const hideDialog = () => {
    dispatch({ type: "hideDialog" });
  };

  const applyText = (color) => {
    // setIsLoading(true);
    const oldTexture = part.textures.find((texture) => {
      return selection[part.name]?.key === texture.key;
    });
    const oldTexturePosition = part.textures.indexOf(oldTexture);
    dispatch({
      type: "selectTexture",
      payload: {
        oldTexturePosition: oldTexturePosition,
        customTexture: {
          key: uuidv4(),
          value: text,
          font_size: fontSize,
          font_family: fontFamily,
          text_color: color ? color : textColor,
          type: "image_text",
        },
      },
    });
  };

  const handleClickOpenFontDialog = () => {
    setOpenFontDialog(true);
  };

  const handleCloseFontDialog = (value) => {
    setOpenFontDialog(false);
    setFontFamily(value);
  };

  const checkActive = (color) => {
    if (selection[part.name]?.text_color === color?.value) {
      return true;
    } else {
      return false;
    }
  };

  const updateFontSize = (fontSize) => {
    if (fontSize < 8) {
      fontSize = 8;
    } else if (fontSize > 22) {
      fontSize = 22;
    }
    setFontSize(fontSize);
  }

  return (
    <Tabs>
      <TabList style={{ textAlign: "left", marginBottom: "0px" }}>
        <Tab style={{ backgroundColor: "#d6d3d3" }}>
          <span>{part?.tab_name}</span>
        </Tab>
        <Tab style={{ backgroundColor: "#d6d3d3" }}>
          <span>カラー</span>
        </Tab>
        <button
          className={"react-tabs__tab close-dialog"}
          style={{ float: "right", backgroundColor: "#d6d3d3" }}
          onClick={() => hideDialog()}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </TabList>

      <TabPanel>
        <div className={styles.dialogBodyContent}>
          <div style={{ marginBottom: "10px" }}><small>ロゴのトリミング、変更が可能です。背景透過のPNGを使用して下さい。</small></div>
          <div className={styles.textures} style={{ width: "260px"}}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              <Form style={{ textAlign: "left", fontSize: "0.7rem" }}>
                <Form.Group style={{ marginBottom: "0.2rem" }}>
                  <Form.Label style={{ marginBottom: "0.1rem" }}>
                    テキスト
                  </Form.Label>
                  <Form.Control
                    maxLength="15"
                    type="text"
                    style={{ fontFamily: fontFamily }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </Form.Group>

                <Form.Group style={{ marginBottom: "0.2rem" }}>
                  <Form.Label style={{ marginBottom: "0.1rem" }}>
                    フォント
                  </Form.Label>
                  <InputGroup className="mb-1">
                    <Form.Control
                      as="div"
                      style={{ fontFamily: fontFamily, cursor: "pointer" }}
                      onClick={handleClickOpenFontDialog}
                    >
                      {fontFamily}
                    </Form.Control>
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        onClick={handleClickOpenFontDialog}
                      >
                        <FontAwesomeIcon icon={faAngleDown} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label htmlFor="fontSize">フォントサイズ</Form.Label>
                    <InputGroup className="mb-1">
                      <InputGroup.Prepend>
                        <Button
                          variant="outline-secondary"
                          onClick={() => updateFontSize(fontSize - 1)}
                        >
                          -
                        </Button>
                      </InputGroup.Prepend>
                      <FormControl
                        id="fontSize"
                        readOnly={true}
                        aria-describedby="basic-addon1"
                        value={fontSize + 'px'}
                        onChange={(e) => updateFontSize(e.target.value)}
                      />
                      <InputGroup.Append>
                        <Button
                          variant="outline-secondary"
                          onClick={() => updateFontSize(fontSize + 1)}
                        >
                          +
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>

                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="primary"
                    onClick={() => applyText()}
                    style={{ width: "4rem" }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                </div>
              </Form>

              <FontDialog
                selectedValue={fontFamily}
                open={openFontDialog}
                onClose={handleCloseFontDialog}
                fonts={part.fonts}
              ></FontDialog>
            </div>
          </div>
        </div>
      </TabPanel>

      <TabPanel>
        <div className={styles.dialogBodyContent}>
          <div className={styles.textures}>
            {part?.colors?.map((color, index) => {
              return (
                <div className={colorStyles.btnWrapper} key={index}>
                  <div
                    onClick={() => {
                      applyText(color.value);
                    }}
                    className={
                      checkActive(color)
                        ? colorStyles.outLineCircleActive
                        : colorStyles.outLineCircle
                    }
                  >
                    <p
                      className={colorStyles.circle}
                      style={{ backgroundColor: color.value }}
                    ></p>
                    <p className={colorStyles.circleText}>
                      {color.display_text}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </TabPanel>
    </Tabs>
  );
}
