import axios from "axios";
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";

const ModelContext = React.createContext();

var _settings = {};
var _modelPosition = 0;
var _partPosition = -1;
var _partTextureMapping = {};
var _shouldTextureDialogShown = false;
var _undoStack = [];
var _redoStack = [];

function modelReducer(state, action) {
  switch (action.type) {
    case "selectModel": {
      return selectModel(action.payload);
    }
    case "selectPart": {
      return selectPart(action.payload);
    }
    case "selectTexture": {
      return selectTexture(action.payload);
    }
    case "hideDialog": {
      return hideDialog();
    }
    case "undo": {
      return undo();
    }
    case "redo": {
      return redo();
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ModelProvider({ children }) {
  const [state, dispatch] = React.useReducer(modelReducer, { scene: {}, model: {} });
  const value = { state, dispatch };
  const { enqueueSnackbar } = useSnackbar();

  // Default value
  useEffect(() => {
    let json_url = "./app_settings.json";
    let params = new URLSearchParams(window.location.search);
    if (params && params.get("file")) {
      json_url = "./" + params.get("file");
    }

    axios
      .get(json_url)
      .then((response) => {
        if (typeof response.data === "string" || response.data instanceof String) {
          _settings = JSON.parse(response.data);
        } else {
          _settings = response.data;
        }
        dispatch({ type: "selectModel", payload: { modelPosition: _modelPosition } });
      })
      .catch((err) => {
        console.log(err);
        failAlert(enqueueSnackbar);
      });
  }, [enqueueSnackbar]);
  return <ModelContext.Provider value={value}>{children}</ModelContext.Provider>;
}

function failAlert(enqueueSnackbar) {
  enqueueSnackbar("Fail to load session", {
    variant: "error",
  });
}

function useModel() {
  const context = React.useContext(ModelContext);
  if (context === undefined) {
    throw new Error("useModel must be used within a ModelProvider");
  }
  return context;
}

function selectModel(payload) {
  _modelPosition = payload.modelPosition;

  if (_settings.user_state) {
    _partTextureMapping = _settings.user_state;
  } else {
    _settings.models[_modelPosition].parts.forEach((part) => {
      _partTextureMapping[part.name] = part.textures.find((texture) => {
        return texture.selected;
      });
    });
  }

  return wrapSetting();
}

function selectPart(payload) {
  _shouldTextureDialogShown = true;
  _partPosition = payload.partPosition;
  return wrapSetting();
}

function selectTexture(payload, action = null) {
  let texturePosition;
  switch (action) {
    case "undo":
      texturePosition = payload.oldTexturePosition;
      break;
    case "redo":
      texturePosition = payload.texturePosition;
      break;
    default:
      if (payload.customTexture) {
        //Push new texture to the app_settings.json
        _settings.models[_modelPosition].parts[_partPosition].textures.push(payload.customTexture);
        texturePosition = _settings.models[_modelPosition].parts[_partPosition].textures.length - 1;
      } else {
        texturePosition = payload.texturePosition;
      }
      break;
  }

  //Save the selection
  _partTextureMapping[_settings.models[_modelPosition].parts[_partPosition].name] =
    _settings.models[_modelPosition].parts[_partPosition].textures[texturePosition];

  if (action == null) {
    payload.partPosition = _partPosition;
    payload.texturePosition = texturePosition;
    _undoStack.push({
      payload: payload,
    });
    _redoStack = [];
  }
  return wrapSetting();
}

function undo() {
  if (_undoStack.length < 1) {
    return wrapSetting();
  }
  const undoToRedo = _undoStack.pop();
  _redoStack.push(undoToRedo);

  selectPart(undoToRedo.payload);
  _shouldTextureDialogShown = false;
  return selectTexture(undoToRedo.payload, "undo");
}

function redo() {
  if (_redoStack.length < 1) {
    return wrapSetting();
  }
  const redoToUndo = _redoStack.pop();
  _undoStack.push(redoToUndo);

  selectPart(redoToUndo.payload);
  _shouldTextureDialogShown = false;
  return selectTexture(redoToUndo.payload, "redo");
}

function hideDialog() {
  _shouldTextureDialogShown = false;
  _partPosition = - 1;
  return wrapSetting();
}

function wrapSetting() {
  // Save the selection
  _settings["user_state"] = _partTextureMapping;
  try {
    return {
      app_settings: _settings,
      scene: _settings.scene,
      model: _settings.models[_modelPosition],
      part: _partPosition >= 0 ? _settings.models[_modelPosition].parts[_partPosition] : null,
      selection: _partTextureMapping,
      shouldTextureDialogShown: _shouldTextureDialogShown,
    };
  } catch (error) {
    return {};
  }
}

export { ModelContext, ModelProvider, useModel };
