import React from "react";
import Image from "react-bootstrap/Image";
import styles from "../scss/Header.module.css";
import MenuDrawer from "./Drawer";

export default function Header(props) {
  return (
    <div>
      <Image src="./logo192.png" className={styles.headerImage}></Image>
      <div className={styles.menuDrawerDiv}>
        <MenuDrawer></MenuDrawer>
      </div>
    </div>
  );
}
