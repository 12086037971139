import React from "react";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUndo,
  faRedo,
  faCamera,
  faSave,
  faShareSquare,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../scss/BottomMenu.module.css";
import { useModel } from "../contexts/ModelContext";
import axios from "axios";
import { useSnackbar } from "notistack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BottomMenu(props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { app_settings },
    dispatch,
  } = useModel();
  const [open, setOpen] = React.useState(false);
  const [orderUrl, setOrderUrl] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const makeOrder = () => {
    let formData = new FormData(); //formdata object
    const blob = new Blob([JSON.stringify(app_settings)], { type: "application/json" });
    formData.append("file", blob); //append the values with key, value pair
    formData.append("type", "json"); //append the values with key, value pair

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
     const createOrderUrl = process.env.REACT_APP_API_URL;
    axios
      .post(createOrderUrl, formData, config)
      .then((success) => {
        console.log(success);
        // enqueueSnackbar("Order successfully!", {
        //   variant: "success",
        // });
        setOrderUrl(success.data);
        handleClickOpen();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Order failed!", {
          variant: "error",
        });
      })
      .then(() => {
        //finally
      });
  };

  return (
    <div className={styles.BottomMenu}>
      <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block" }}>
        <ButtonGroup className="mr-2" aria-label="First group">
          <Button
            variant="outline-secondary"
            onClick={() => {
              dispatch({ type: "undo" });
            }}
          >
            <FontAwesomeIcon icon={faUndo} />
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              dispatch({ type: "redo" });
            }}
          >
            <FontAwesomeIcon icon={faRedo} />
          </Button>
          <Button variant="outline-secondary" id="printscreen">
            <FontAwesomeIcon icon={faCamera} />
          </Button>
          <Button variant="outline-secondary">
            <FontAwesomeIcon icon={faSave} />
          </Button>
          <Button variant="outline-secondary">
            <FontAwesomeIcon icon={faShareSquare} />
          </Button>
        </ButtonGroup>
        <ButtonGroup className="mr-2" aria-label="Second group">
          <Button variant="outline-secondary" onClick={makeOrder} type="submit">
            <FontAwesomeIcon icon={faShoppingBasket} /> ORDER
          </Button>
        </ButtonGroup>
      </ButtonToolbar>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        //keepMounted
        //onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Link to share"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <a href={orderUrl}>Share link</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
